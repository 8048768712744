import React, { useState } from "react";
import cn from "classnames";

import { climateSens, cows as connect, preview } from "../../containers";
import CowPreview from "../CowPreview";
import PairingListPreview from "../PairingListPreview";
import SystemPreview from "../SystemPreview";
import "./Cows.css";
import System from "../System";
import PairingLists from "../PairingLists";
import NewPairingListButton from "../NewPairingListButton";
import Automation from "../Automation";
import ClimateSens from "../ClimateSens";
import AutomationPreview from "../AutomationPreview";
import ClimateSensPreview from "../ClimateSensPreview";
import Toast from "../Toast";

import Cow from "./Cow";

const Cows = ({ cows, preview, farmNumberDigits, automation }) => {
    const [toast, setToast] = useState(false);
    const automationMap = Object.values(automation).reduce(
        (acc, a) => {
            if (a.deviceTypesId === "14") {
                return { ...acc, climateSens: [...acc.climateSens, a] };
            } else if (a.deviceTypesId === "9") {
                return {
                    ...acc,
                    automationDevice: [...acc.automationDevice, a],
                };
            } else {
                return acc;
            }
        },
        { automationDevice: [], climateSens: [] },
    );
    return (
        <section className={cn("cows", `digits-${farmNumberDigits}`)}>
            {toast ? <Toast onFinish={() => setToast(false)} /> : null}
            <System />
            {preview && preview.kind === "system" ? <SystemPreview /> : null}
            {automationMap.automationDevice.map((automation) => {
                return (
                    <React.Fragment key={automation.id}>
                        <Automation id={automation.id} />
                        {preview &&
                        preview.id === automation.id &&
                        preview.kind === "automation" ? (
                            <AutomationPreview id={automation.id} />
                        ) : null}
                    </React.Fragment>
                );
            })}
            {automationMap.climateSens.map((cs) => {
                return (
                    <React.Fragment key={cs.id}>
                        <ClimateSens id={cs.id} />
                        {preview &&
                        preview.id === cs.id &&
                        preview.kind === "automation" ? (
                            <ClimateSensPreview id={cs.id} />
                        ) : null}
                    </React.Fragment>
                );
            })}
            {cows.map((cow) => {
                return (
                    <React.Fragment key={cow}>
                        <Cow id={cow} />
                        {preview &&
                        preview.id === cow &&
                        preview.kind === "cow" ? (
                            <CowPreview id={cow} setToast={setToast} />
                        ) : null}
                    </React.Fragment>
                );
            })}
            <PairingLists />
            {preview && preview.id && preview.kind === "list" ? (
                <PairingListPreview />
            ) : null}
            <NewPairingListButton />
        </section>
    );
};
export default connect(preview()(Cows));
